<template>
  <div class="c-accommodation-search">
    <a v-if="accommodation.meta.type == 'private' " @click.prevent="showModal" href="#" class="btn btn--modal btn--blank">
        <img src="/src/img/accommodation/welcome-logo.png" alt="">
    </a>
    <div
      v-if="accommodation.meta.main_picture == undefined "
      class="c-accommodation-search__img-holder"
    >
      <picture>
        <img
          class="c-accommodation-search__img"
          src="/src/img/accommodation/no_image.png"
          alt="alt"
        />
      </picture>
    </div>
    <div
      v-else-if="accommodation.meta.main_gallery !==  undefined"
      class="c-accommodation-search__img-holder accommodation-search-slider"

    >
		<a v-if="accommodation.meta.type == 'private' " @click.prevent="showModal" href="#" class="btn btn--modal btn--blank">
			<img src="/src/img/accommodation/welcome-logo.png" alt="">
		</a>
        <swiper :options="swiperOption" ref="mySwiper" class="">
          <swiper-slide>
            <picture>
              <img
                class="c-accommodation-search__img"
                :src="accommodation.meta.main_picture.mainImagePath"
                alt="alt"
              />
            </picture>
          </swiper-slide>
          <swiper-slide v-for="image in accommodation.meta.main_gallery"
            :key="image.id">
              <picture>
                <img
                  class="c-accommodation-search__img"
                  :src="'/en/vdatastore/image?id=' + image.id + '&variation=720x720'"
                  alt="alt"
                />
            </picture>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>

          <div class="swiper-button-next" slot="button-next"><svg class="swiper-button__arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8">
                            <path fill="#1D1D1B" fill-rule="evenodd" d="M16 4l-4-4v3H0v2h12v3z"/>
                        </svg></div>
            <div class="swiper-button-prev" slot="button-prev">
              <svg class="swiper-button__arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8">
                            <path fill="#1D1D1B" fill-rule="evenodd" d="M0 4l4-4v3h12v2H4v3z"/>
                        </svg>
            </div>

        </swiper>
    </div>
    <div v-else class="c-accommodation-search__img-holder">
      <picture>
        <img
          class="c-accommodation-search__img"
          :src="accommodation.meta.main_picture.mainImagePath"
          alt="alt"
        />
      </picture>
    </div>

    <div class="c-accommodation-search__texts">
      <h1 class="c-accommodation-search__title">
        <span class="c-accommodation-search__link">{{accommodation.meta.name}}</span>
      </h1>

      <div class="c-accommodation-search__info-wrap">

      	<!-- TODO Josip - accommodation type -->
          <!-- v-if="accommodation.meta.stars !== undefined && accommodation.meta.stars != '' " -->
        <div v-if="accommodation.content.object_type !== undefined && accommodation.content.object_type != ''"
          class="c-accommodation-search__ratings"
        >
	        <div class="c-accommodation-search__stars">
	        	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	        		<path d="M9 0L0 8.34854H2.12031V18H6.65815V10.8312H11.3418V18H15.8797V15.1454V8.34854H18L9 0Z" fill="#5DC5F1"/>
	        	</svg>
	        </div>
			<span class="c-accommodation-search__ratings-text">
					{{accommodation.content.object_type}}
			</span>
        </div>

        <!-- TODO Josip - amenities -->
          <!-- v-if="accommodation.meta.stars !== undefined && accommodation.meta.stars != '' " -->
        <div v-if="accommodation.meta.ammenity !== undefined && accommodation.meta.ammenity != '' && accommodation.meta.ammenity != 'undefined' "
          class="c-accommodation-search__ratings"
        >
	        <div class="c-accommodation-search__stars">
	        	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	        		<path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="#5DC5F1"/>
	        		<path d="M3.87866 9.35093L7.02451 12.4968L13.628 5.89331" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
	        	</svg>
	        </div>
          <span v-for="(item) in accommodation.meta.ammenity"
		  		 :key="item" class="c-accommodation-search__ratings-text">
           		{{msg["search_ammenity_"+item]}}
          </span>
        </div>


        <div
          v-if="parseInt(accommodation.meta.stars)"
          class="c-accommodation-search__ratings"
        >
          <div class="c-accommodation-search__stars">
            <svg
              v-for="stars in parseInt(accommodation.meta.stars)"
              :key="stars"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
            >
              <polygon
                fill="#5DC5F1"
                fill-rule="evenodd"
                points="54.5 12.135 49.504 15.377 51.043 9.623 46.416 5.873 52.363 5.559 54.5 0 56.637 5.559 62.584 5.873 57.957 9.623 59.496 15.377"
                transform="translate(-46)"
              />
            </svg>
          </div>
          <span class="c-accommodation-search__ratings-text">
            Rating
            <strong>{{accommodation.meta.stars}} stars</strong>
          </span>
        </div>

        <div
          v-if="accommodation.meta.capacity !== undefined && accommodation.meta.capacity != '' "
          class="c-accommodation-search__ratings"
        >
          <div class="c-accommodation-search__capacity">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <path
                fill="#5DC5F1"
                fill-rule="evenodd"
                d="M9,13.25 C12.00375,13.25 18,14.7575 18,17.75 L18,20 L0,20 L0,17.75 C0,14.7575 5.99625,13.25 9,13.25 Z M9,11 C6.51471863,11 4.5,8.98528137 4.5,6.5 C4.5,4.01471863 6.51471863,2 9,2 C11.4852814,2 13.5,4.01471863 13.5,6.5 C13.5,8.98528137 11.4852814,11 9,11 Z"
                transform="translate(0 -2)"
              />
            </svg>
          </div>
          <span class="c-accommodation-search__ratings-text">
            {{msg.accommodation_capacity}}
            <strong>{{accommodation.meta.capacity}}pax</strong>
          </span>
        </div>
      </div>

      <hr />
      <div class="c-accommodation-search__list">
        <div class="c-accommodation-search__list-item-wrap">
          <dt class="c-accommodation-search__list-item-title">{{msg.block_label_adress}}</dt>
          <dd
            class="c-accommodation-search__list-item-description"
          >{{accommodation.meta.address}}, {{accommodation.meta.post_code}} {{accommodation.city_meta.name}}</dd>
        </div>
        <div class="c-accommodation-search__list-item-wrap">
          <dt class="c-accommodation-search__list-item-title">{{msg.block_label_phone}}</dt>
          <dd class="c-accommodation-search__list-item-description">{{accommodation.meta.contact}}</dd>
        </div>
        <div
          v-if="accommodation.meta.email !== undefined"
          class="c-accommodation-search__list-item-wrap"
        >
          <dt class="c-accommodation-search__list-item-title">{{msg.block_label_email}}</dt>
          <dd class="c-accommodation-search__list-item-description">
            <a :href="'mailto:' + accommodation.meta.email">{{accommodation.meta.email}}</a>
          </dd>
        </div>
        <div
          v-if="accommodation.meta.web_link !== '' && accommodation.meta.web_link !== undefined"
          class="c-accommodation-search__list-item-wrap"
        >
          <dt class="c-accommodation-search__list-item-title">{{msg.block_label_website}}</dt>
          <dd class="c-accommodation-search__list-item-description">
            <a :href="accommodation.meta.web_link | urlChecker" target="_blank">{{accommodation.meta.web_link}}</a>
          </dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { mapState } from "vuex";

export default {
  props: {
    accommodation: Object
  },
   components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      //
      swiperOption: {
        fixedHeight: 380,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction'
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
      }
    };
  },
  methods: {
    showModal() {
      this.$store.commit("toggleAccommodationModal");
    }
  },
  computed: {
	...mapState({
		msg: state => state.msg
	}
	),
  },
  mounted() {

  },
};
</script>

<style lang="scss">

</style>
