import Vue from 'vue';
// Vue.config.devtools = true;

import store from './store';
import { debounce } from 'lodash';
import axios from 'axios';


window.axios = require('axios');

Vue.component('base-state-init', require('./components/BaseStateInit').default);
Vue.component('project-signup-form', require('./components/ProjectSignupForm').default);
Vue.component('tourist-boards-search', require('./components/TouristBoardSearch').default);
Vue.component('agency-search', require('./components/AgencySearch').default);
Vue.component('accommodation-search', require('./components/AccommodationSearch').default);
Vue.component('AccommodationSearchCard', require('./components/AccommodationSearchCard').default);
Vue.component('accommodation-modal', require('./components/AccommodationModal').default);

//add global url filter
Vue.filter("urlChecker", val => { 
	val = val.trim().replace(/\s/g, "");
	if(/^(:\/\/)/.test(val)){
		return `http${val}`;
	}
	if(!/^(f|ht)tps?:\/\//i.test(val)){
		return `http://${val}`;
	}
	return val;
});

new Vue({
	store,
	el: '#app',
});
