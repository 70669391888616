<script>
export default {
	props: {
		msgkeys: String
	},
	created() {
		this.$store.commit('changeMsg', JSON.parse(this.msgkeys));
	}

}
</script>
