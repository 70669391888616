<template>
	<div>

		<div class="filters-wrap">

			<div class="filters-wrap__item">
				<label class="c-search-form__title" for="search">{{msg.search_category}}</label>
				<multiselect
					@input="resetArticleCount"
					v-model="selectedCategory"
					:options="categories"
					track-by="id"
					:searchable="false"
					:close-on-select="true"
					:show-labels="false"
					label="name"
					class="multiselect-light js-select-job-type"
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
				</multiselect>
			</div>

			<div class="filters-wrap__item">
				<label class="c-search-form__title" for="search">{{msg.search_location}}</label>
				<input
					:placeholder="msg.search_location"
					class="c-search-form__input c-search-form__input--light c-search-form__input--small"
					type="text"
					v-model="searchFilter"
					@input="resetArticleCount"
					name="search"
				/>
				<!-- <vue-bootstrap-typeahead
							v-model="searchFilter"
							:data="locations"
							inputClass="c-search-form__input"
						>
							<template slot="suggestion" slot-scope="{ data, htmlText }">
								<span class="c-accommodation-search__search-suggestion" v-html="htmlText"></span>&nbsp;<small>{{ data.code }}</small>
							</template>
				</vue-bootstrap-typeahead>-->
				<!-- <input v-model="searchFilter" class="c-search-form__input" type="text" name="" id=""> -->
			</div>

		</div>

		<div class="row">
			<div class="col-12 col-md-6 col-lg-12">
				<AccommodationSearchCard
					v-for="(accommodation, index) in data"
					:key="index"
					:accommodation="accommodation"
					:categories="categories"
				></AccommodationSearchCard>
				<div v-if="!data.length" class="col-md-12 text-center">
					<p>{{msg.search_noresults}}</p>
				</div>
				<div class="col-md-12 text-center">
					<a
						v-if="(data.length > articleCount - 1)"
						href="#"
						@click.prevent="loadMore"
						class="btn btn--load"
					>{{msg.btn_load_more}}</a>
				</div>
			</div>
		</div>
		<accommodation-modal v-if="showAccommodationModal"></accommodation-modal>
	</div>
</template>

<script>
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";

export default {
	props:{
		lang:String
	},
	created() {


		this.categories[0].name = this.msg.search_select_all;
		this.categories[1].name = this.msg.search_gastro_categ_restaurants;
		this.categories[2].name = this.msg.search_gastro_categ_ffarm;
		this.categories[3].name = this.msg.search_gastro_categ_trade;
		this.categories[4].name = this.msg.search_gastro_categ_self_sufficient;
		this.categories[5].name = this.msg.search_gastro_categ_tasting_room;
		this.categories[5].name = this.msg.search_gastro_categ_winery;
		this.categories[5].name = this.msg.search_gastro_categ_olive_oil;
		this.categories[5].name = this.msg.search_gastro_categ_cheese;
		this.categories[5].name = this.msg.search_gastro_categ_tavern;

		const urlParams = new URLSearchParams(window.location.search);
		const categoryId = urlParams.get("type");

		if (categoryId) {
			const preselectedCategory = this.categories.find(cat => cat.id === categoryId);
			if (preselectedCategory) {
				this.selectedCategory = preselectedCategory;
			}
			else
			{
				this.selectedCategory = this.categories[0];
			}
		}
		else
		{
			this.selectedCategory = this.categories[0];
		}

	},
	data() {
		return {
			data: [],
			searchFilter: "",
			categories: [
				{
					id: "",
					name: "Select All"
				},
				{
					id: "restaurants",
					name: "Restaurants"
				},
				{
					id: "farm",
					name: "Family Farm"
				},
				{
					id: "holding",
					name: "Agricultural Holding"
				},
				{
					id: "trade",
					name: "Trade"
				},
				{
					id: "selffarm",
					name: "Self-Sufficient Family Farm"
				},
				{
					id: "tasting",
					name: "Tasting Room"
				},
				{
					id: "winery",
					name: "Winery (Wine Tasting Room)"
				},
				{
					id: "oliveoil",
					name: "Olive Oil Tasting Room"
				},
				{
					id: "cheese",
					name: "Cheese Tasting Room"
				},
				{
					id: "inn",
					name: "Traditional Inn / Tavern"
				}
			],
			selectedCategory: {},
			firstView: true,
			articleCount: 5,
			accommodationModal: false,
			data: []
		};
	},
	components: {
		VueBootstrapTypeahead,
		Multiselect
	},
	computed: {
		...mapState({
			showAccommodationModal: state => state.showAccommodationModal,
			msg: state => state.msg
		})
	},
	methods: {
		fetchItems()
		{
			const self = this;
			// console.log(this.lang);
			axios
				.get("/" + this.lang + "/xgastro", {
					params: {
						location: this.searchFilter,
						category: this.selectedCategory.id,
						limit: this.articleCount
					}
				})
				.then(function (response)
				{
					if (response.data.success)
					{
						self.data = response.data.data;
					}
				})
				.catch(function (error)
				{
					// console.log(error);
				});
		},
		loadMore()
		{
			this.firstView == false;
			this.articleCount += 5;

			this.fetchItems();
		},
		resetArticleCount()
		{
			if (this.searchFilter.length < 2)
			{
				this.articleCount = 5;
			}
		}
	},
	watch: {
		searchFilter: _.debounce(function (query)
		{
			this.fetchItems();
		}, 500),
		selectedCategory: _.debounce(function (query)
		{
			this.fetchItems();
		}, 500),

	}
};
</script>

<style lang="scss">

//temp multiselect styling
.multiselect-light {
	width: 100%;
	box-shadow: inset 0 0 0 1px #DFDFDF;
	background-color: #fff;
	border: none;
	margin-bottom: 22px;
	font-size: 16px;
	color: #1D1D1B;
	padding: 18px 16px;
	position: relative;

	@media (min-width: 992px) {
		margin-bottom: 0;
	}

	&--active {

	}

	.multiselect__content {
		background-color: #fff;
		margin: 0;
		overflow-y: auto;
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		top: 100%;
		z-index: 16;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		padding-left: 0;
		list-style-type: none;
		-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	}

	.multiselect__element {
		background-color: #fff;
		margin: 0;
		min-width: 144px;
		overflow-y: auto;
		top: calc(100% + 20px);
		z-index: 10;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		padding-left: 0;
		list-style-type: none;
	}

	.multiselect__select {
		position: relative;

		&:before {
			position: absolute;
			right: 0;
			top: 65%;
			color: #1D1D1B;
			margin-top: 10px;
			border-style: solid;
			border-width: 6px 6px 0;
			border-color: #1D1D1B transparent transparent;
			content: "";
		}
	}

	.multiselect__option {
		font-size: 14px;
		font-size: 1.4rem;
		line-height: 1.28571em;
		font-weight: 400;
		color: #1D1D1B;
		background-color: #fff;
		padding: 20px;
		display: block;
		cursor: pointer;
		-webkit-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
		transition: color 250ms ease-in-out, background-color 250ms ease-in-out;

		&--highlight, --selected {
			background-color: #1d1d1b;
			color: #ebebeb;
			padding: 20px;
		}

	}
}


.filters-wrap {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	position: relative;
	z-index: 12;

	@media (min-width: 992px) {
		// flex-wrap: nowrap;
		gap: 30px;
		margin-bottom: 30px;
	}

	&__item {
		position: relative;
		// z-index: 2;
		flex-basis: 100%;

		@media (min-width: 992px) {
			// flex: 0 1 218px;
			flex: 0 1 calc(50% - 15px);
		}
	}

	&__item--small {
		@media (min-width: 992px) {
			flex: 0 0 144px;
		}
	}

	.c-search-form__input {
		height: 60px;

		@media (min-width: 992px) {
			margin-bottom: 0;
		}
	}

}

</style>

