<template>
    <transition name="modal-next">
        <div class="c-accommodation-modal">
            <div class="c-accommodation-modal__mask">
                <div class="c-accommodation-modal__wrapper">
                    <div class="c-accommodation-modal__container">
                        <div @click.prevent="hideAccommodationModal" class="c-accommodation-modal__close-icon">
                            <a href>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                                    <path fill="#FFF" fill-rule="evenodd" d="M9.243 2.172L7.828.757 5 3.586 2.172.757.757 2.172 3.586 5 .757 7.828l1.415 1.415L5 6.414l2.828 2.829 1.415-1.415L6.414 5z"/>
                                </svg>
                            </a>
                        </div>
                        <div class="c-accommodation-modal__content-wrap">
                            <div class="c-accommodation-modal__body">	
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img src="/src/img/welcome-logo@2x.png" alt="">
                                    </div>
                                    <div class="col-12 text-center">
                                        <h1 class="c-accommodation-modal__content-title">
                                            {{msg.accommodation_modal_title}}
                                        </h1>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class="c-accommodation-modal__content-intro">
                                            {{msg.accommodation_modal_intro}}
                                        </p>
                                        <p class="c-accommodation-modal__content-paragraph">
                                            {{msg.accommodation_modal_text1}}
                                        </p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="c-accommodation-modal__content-paragraph">
                                            {{msg.accommodation_modal_text2}}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <p class="c-accommodation-modal__content-footer">
                                            {{msg.accommodation_modal_footer}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            
        }
      },
    props: {

    },
    created() {
        
    },
    methods: {
        hideAccommodationModal() {
              this.$store.commit("toggleAccommodationModal");
        }
    },
    computed: {
        ...mapState({
          msg: state => state.msg
        })
    },
};
</script>

<style>
    
</style>
