<template>
	<div class="container project-form">
		<div class="row">
			<div class="col-lg-10 col-12 u-order-sm-2">
				<div v-if="currentStep == 1">
					<h2 class="block-category-title">Prijava projekta</h2>
<!--					<p>-->
<!--						Prije same prijave preporučamo vam da proučite tekst <a class="block-category-link" href="https://www.zadar.hr/datastore/filestore/20/Javni-poziv-za-dodjelu-potpora-dogadanjima-u-2025.pdf" target="_blank">Javnog poziva za dodjelu potpora događanjima u 2025.</a>, te <a class="block-category-link" href="https://www.zadar.hr/datastore/filestore/20/2025_Javni-poziv-za-dodjelu-potpora-programima-DMK.pdf" target="_blank">Javnog poziva za DMK 2025.</a> i obrascima: <a class="block-category-link" href="https://www.zadar.hr/datastore/filestore/20/Obrasci-za-dogadanja-2025.docx">Obrasci za događanja</a>, <a class="block-category-link" href="https://zadar.hr/datastore/filestore/20/2025_Obrasci_JP-za-dodjelu-potpora-programima-DMK.docx">Obrasci za DMK</a> te pripremite svoje odgovore prije samog ispunjavanja online obrasca.-->

<!--					</p>-->

					<p>
					Prije same prijave preporučamo vam da proučite tekst <a class="block-category-link" href="/datastore/file/353/JP_TZZZ_2025_POSEBNI-INTERESI.pdf" target="_blank">Javnog poziva za dodjelu potpora za razvoj turističkih proizvoda posebnih interesa</a> i <a class="block-category-link" href="https://zadar.hr/datastore/file/354/2025_Obrasci_JP-za-razvoj-turistickih-proizvoda-posebnih-interesa.docx">Obrasce za JP</a> te pripremite svoje odgovore prije samog ispunjavanja online obrasca.

					</p>
					<p>
						<strong>
							Prijava se sastoji od nekoliko koraka
						</strong>
						<ol>
							<li>
								Ispunite interaktivni online obrazac - zahtjev za prijavu.
							</li>
							<li>
								Nakon završetka ispunjavanja online obrasca, isprintajte ga.
							</li>
							<li>
								Priložite Zahtjev i ostale dokumente iz poziva te sve pošaljite na adresu: <br />
								<strong>Turistička zajednica Zadarske županije, </strong><br />
								<strong>Jurja Barakovića 5</strong><br />
								<strong>23000 Zadar</strong>
							</li>
						</ol>

						Kandidature s nepotpunom dokumentacijom i nepotpuno ispunjenim obrascima iz Javnog poziva neće se razmatrati. Online prijave za Javni poziv za dodjelu potpora za razvoj turističkih proizvoda posebnih interesa zatvaraju se 25. travnja 2025. u 12:00.

						<!-- Kandidature s nepotpunom dokumentacijom i nepotpuno ispunjenim obrascima iz Javnog poziva neće se razmatrati. On-line prijave za Javni poziv za dodjelu potpora događanjima u 2024. zatvaraju se <strong>24. siječnja</strong> dok se on-line prijave za Javni poziv za DMK 2024. zatvaraju <strong>30. siječnja.</strong> -->
					</p>
					<!-- <p>Kandidature bez izvršene on-line prijave neće se razmatrati.</p> -->
				</div>
				<div v-if="errorMsg" class="form-status form-status--error">
					Došlo je do greške. Molimo provjerite označena polja i pokušajte ponovno.
				</div>
				<form id="project-form" v-show="currentStep > 1" action="">
					<div v-show="currentStep == 2" class="form-div--2">
						<label class="form-item">
							<span class="form-item__label">
								Naziv projekta
							</span>
							<input type="text" name="event_name" value="" class="form-item__input js-form-input">
						</label>


						<label class="form-item">
							<span class="form-item__label">
								Model za koji se prijavljuje:
							</span>
						</label>
<!--						<label class="form-item" for="event_model1">-->
<!--							<input type="radio" name="event_model" id="event_model1" value="POTPORE DOGAĐANJIMA" class="js-form-input">-->
<!--							<span class="form-item__label">-->
<!--								POTPORE DOGAĐANJIMA-->
<!--							</span>-->
<!--						</label>-->





						<label class="form-item" for="event_model3">
							<input type="radio" name="event_model" id="event_model3" value="Javni poziv za dodjelu potpora u 2025. za razvoj turističkih proizvoda posebnih interesa." class="js-form-input">
							<span class="form-item__label">
<!--								JAVNI POZIV ZA DODJELU POTPORA ZA RAZVOJ PROGRAMA TURISTIČKIH PROIZVODA POSEBNIH INTERESA U 2025.-->
								Javni poziv za dodjelu potpora u 2025. za razvoj turističkih proizvoda posebnih interesa.
							</span>
						</label>



<!--						<label class="form-item" for="event_model3">-->
<!--							<input type="radio" name="event_model" id="event_model3" value="POTPORE DMK" class="js-form-input">-->
<!--							<span class="form-item__label">-->
<!--								POTPORE DMK-->
<!--							</span>-->
<!--						</label>-->

<!--						 <label class="form-item" for="event_model2">-->
<!--							<input type="radio" name="event_model" id="event_model2" value="POTPORE DMK" class="js-form-input">-->
<!--							<span class="form-item__label">-->
<!--								POTPORE DMK-->
<!--							</span>-->
<!--						</label>-->
<!--						 <label class="form-item" for="event_model3">-->
<!--							<input type="radio" name="event_model" id="event_model3" value="POTPORE RAZVOJNIM PROJEKTIMA/PROGRAMIMA OD POSEBNOG INTERESA" class="js-form-input">-->
<!--							<span class="form-item__label">-->
<!--								POTPORE RAZVOJNIM PROJEKTIMA/PROGRAMIMA OD POSEBNOG INTERESA-->
<!--							</span>-->
<!--						</label>-->




						<!-- <label class="form-item">
							<span class="form-item__label">
								Vrsta suradnje:
							</span>
						</label>
						<label class="form-item" for="event_cooperation1">
							<input type="checkbox" v-model="eventCooperation"  id="event_cooperation1" value="Izrada promotivnih materijala" class="js-form-input">
							<span class="form-item__label">
								Izrada promotivnih materijala
							</span>
						</label>
						<label class="form-item" for="event_cooperation5">
							<input type="checkbox" v-model="eventCooperation"  id="event_cooperation5" value="Oglašavanje" class="js-form-input">
							<span class="form-item__label">
								Oglašavanje
							</span>
						</label>
						<label class="form-item" for="event_cooperation2">
							<input type="checkbox" v-model="eventCooperation" id="event_cooperation2" value="Distribucija (sajmovi, radionice, prezentacije)" class="js-form-input">
							<span class="form-item__label">
								Distribucija (sajmovi, radionice, prezentacije)
							</span>
						</label>
						<label class="form-item" for="event_cooperation3">
							<input type="checkbox" v-model="eventCooperation" id="event_cooperation3" value="Edukacija" class="js-form-input">
							<span class="form-item__label">
								Edukacija
							</span>
						</label>
						<label class="form-item" for="event_cooperation4">
							<input type="checkbox" v-model="eventCooperation" id="event_cooperation4" value="Programi razvoja i unapređenja ključnih proizvoda" class="js-form-input">
							<span class="form-item__label">
								Programi razvoja i unapređenja ključnih proizvoda
							</span>
						</label> -->

						<input type="hidden" name="event_cooperation" :value="eventCooperation">
						<label class="form-item">
							<span class="form-item__label">
								Datum realizacije projekta
							</span>
							<input type="text" name="event_date" value="" class="form-item__input js-form-input">
						</label>


						<label class="form-item">
							<span class="form-item__label">
								Cilj projekta (150 znakova)
							</span>
							<input type="text" name="project_target_market" maxlength="150" value="" class="form-item__input js-form-input">
						</label>

						<!-- <label class="form-item">
							<span class="form-item__label">
								Ciljana tržišna skupina
							</span>
							<input type="text" name="project_target_group" value="" class="form-item__input js-form-input">
						</label> -->

						<label class="form-item">
							<span class="form-item__label">
								Web stranica
							</span>
							<input type="text" name="project_website" value="" class="form-item__input js-form-input">
						</label>

						<label class="form-item">
							<span class="form-item__label">
								Destinacija
							</span>
							<input type="text" name="project_destination" value="" class="form-item__input js-form-input">
						</label>

						<label class="form-item">
							<span class="form-item__label ">
								Kratak opis projekta (500 znakova)
							</span>
							<textarea name="project_description" maxlength = "500" class="form-item__textarea js-form-input"></textarea>
						</label>

						<label class="form-item">
							<span class="form-item__label ">
								Kratak opis prijedloga aktivnosti za koje se traži potpora (500 znakova)
							</span>
							<textarea name="project_activity_proposal" maxlength = "500" class="form-item__textarea js-form-input"></textarea>
						</label>
					</div>
					<div v-show="currentStep == 3" class="form-div--3">
						<label class="form-item">
							<span class="form-item__label">
								Organizator
							</span>
							<input type="text" name="event_organizer" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Adresa / sjedište
							</span>
							<input type="text" name="event_organizer_address" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								E-mail
							</span>
							<input type="text" name="event_organizer_email" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Web stranica
							</span>
							<input type="text" name="event_organizer_web" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								OIB
							</span>
							<input type="text" name="event_organizer_oib" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Registrirana pri
							</span>
							<input type="text" name="event_organizer_registred" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Reg. broj
							</span>
							<input type="text" name="event_organizer_registered_number" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Odgovorna osoba (ime i prezime)
							</span>
							<input type="text" name="event_organizer_responsible_person" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Funkcija odgovorne osobe
							</span>
							<input type="text" name="event_organizer_responsible_person_role" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Kontakt-osoba(ime, prezime, funkcija, e-mail, broj telefona)
							</span>
							<input type="text" name="event_organizer_contact_person" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Partneri
							</span>
							<textarea name="event_coorganizers" class="form-item__textarea js-form-input"></textarea>
						</label>
					</div>
					<div v-show="currentStep == 4" class="form-div--4">
						<label class="form-item">
							<span class="form-item__label ">
								Ukupna vrijednost planiranih aktivnosti u 2025. u EUR za koje se traži potpora:
							</span>
							<textarea name="project_value" class="form-item__textarea js-form-input"></textarea>
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Iznos tražene potpore u EUR
							</span>
							<input type="text" name="project_cost_backing" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Udio tražene potpore u ukupnoj vrijednosti planiranih aktivnosti u %
							</span>
							<input type="text" name="project_cost_share" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Mjesto i datum
							</span>
							<input type="text" name="project_form_timedate" value="" class="form-item__input js-form-input">
						</label>
					</div>
					<!-- <div v-show="currentStep == 5" class="form-div--5">
						<p>PODACI O SLOŽENOM TURISTIČKOM PROIZVODU/LINIJI PROIZVODA POSEBNIH INTERESA
							(dalje u tekstu: proizvod)
							(obrazac se popunjava zasebno za svaku kandidiranu liniju turističkih proizvoda, tj. turistički proizvod)
						</p>
						<label class="form-item">
							<span class="form-item__label">
								Točan naziv proizvoda
							</span>
							<input type="text" name="product_name" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Proizvod spada u skupinu(označi):
							</span>
						</label>
						<label class="form-item" for="product_group1">
							<input type="radio" id="product_group1" name="product_group" value="AKTIVNOSTI I AVANTURE(biciklističke i pješačke ture, kajaking ture, rafting; penjanje i drugi slični proizvodi)" class="js-form-input">
							<span class="form-item__label">
								AKTIVNOSTI I AVANTURE(biciklističke i pješačke ture, kajaking ture, rafting; penjanje i drugi slični proizvodi)
							</span>
						</label>
						<label class="form-item" for="product_group2">
							<input type="radio" id="product_group2" name="product_group" value="Distribucija (sajmovi, radionice, prezentacije)" class="js-form-input">
							<span class="form-item__label">
								NAUTIČKI turizam (ture po otocima, jedrenje, ronjenje i sl.)
							</span>
						</label>
						<label class="form-item" for="product_group3">
							<input type="radio" id="product_group3" name="product_group" value="Edukacija" class="js-form-input">
							<span class="form-item__label">
								GASTRO I ENO (opće ili tematske gastronomske ture, vinske ture i dr.)
							</span>
						</label>
						<label class="form-item" for="product_group4">
							<input type="radio" id="product_group4" name="product_group" value="Programi razvoja i unapređenja ključnih proizvoda" class="js-form-input">
							<span class="form-item__label">
								KULTURA (kulturne i povijesne ture, kreativni turizam i drugi slični proizvodi)
							</span>
						</label>
						<label class="form-item" for="product_group5">
							<input type="radio" id="product_group5" name="product_group" value="Programi razvoja i unapređenja ključnih proizvoda" class="js-form-input">
							<span class="form-item__label">
								POSEBNI INTERESI (seoski turizam; promatranje ptica, i dr.)
							</span>
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Godina izlaska na tržište
							</span>
							<input type="text" name="product_releaseyr" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Opis ciljane skupine – tržišne niše:
							</span>
							<textarea name="product_marketniche" class="form-item__textarea js-form-input"></textarea>
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Opis sadržaja – aktivnosti obuhvaćenih programom:
							</span>
							<textarea name="product_activity" class="form-item__textarea js-form-input"></textarea>
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Područje izvođenja proizvoda
							</span>
							<input type="text" name="product_scope" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Trajanje (broj dana i noćenja)
							</span>
							<input type="text" name="product_duration" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Vrsta prijevoza
							</span>
							<input type="text" name="product_transport" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Razdoblje izvođenja proizvoda/polasci (zaokruži)
							</span>
						</label>
						<label class="form-item" for="product_period1">
							<input type="radio" id="product_period1" name="product_period" value="Tijekom cijele godine" class="js-form-input">
							<span class="form-item__label">
								Tijekom cijele godine
							</span>
						</label>
						<label class="form-item" for="product_period2">
							<input type="radio" id="product_period2" name="product_period" value="U određenom razdoblju/mjesecima (navesti razdoblje)" class="js-form-input">
							<span class="form-item__label">
								U određenom razdoblju/mjesecima (navesti razdoblje)
							</span>
						</label>
						<label class="form-item" for="product_period3">
							<input type="radio" id="product_period3" name="product_period" value="Povremeno/na upit" class="js-form-input">
							<span class="form-item__label">
								Povremeno/na upit
							</span>
						</label>
						<label class="form-item">
							<span class="form-item__label">
								Minimalan broj osoba
							</span>
							<input type="text" name="product_minpeople" value="" class="form-item__input js-form-input">
						</label>

						<p>Ukupna uložena/planirana sredstva za promotivne aktivnosti za proizvod (u EUR):</p>

						<label class="form-item">
							<span class="form-item__label ">
								Promo.mater. - 2018.
							</span>
							<input type="text" name="product_investment_promo_2017" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Promo.mater. - 2019.
							</span>
							<input type="text" name="product_investment_promo_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Promo.mater. - 2020. plan
							</span>
							<input type="text" name="product_investment_promo_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Oglašavanje - 2018.
							</span>
							<input type="text" name="product_investment_ads_2017" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Oglašavanje - 2019.
							</span>
							<input type="text" name="product_investment_ads_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Oglašavanje - 2020. plan
							</span>
							<input type="text" name="product_investment_ads_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Distribucija - 2018.
							</span>
							<input type="text" name="product_investment_distribution_2017" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Distribucija - 2019.
							</span>
							<input type="text" name="product_investment_distribution_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Distribucija - 2020. plan
							</span>
							<input type="text" name="product_investment_distribution_2019" value="" class="form-item__input js-form-input">
						</label>
						<p>
							Razrada izrade promotivnih alata/materijala (u EUR)
						</p>
						<label class="form-item">
							<span class="form-item__label ">
								Izrada tekstova i njihov prijevod na strane jezike - 2018.
							</span>
							<input type="text" name="product_promo_text_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Izrada tekstova i njihov prijevod na strane jezike - 2019.
							</span>
							<input type="text" name="product_promo_text_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Izrada tekstova i njihov prijevod na strane jezike - 2020.(plan)
							</span>
							<input type="text" name="product_promo_text_2020" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Izrada audio i video materijala, oglasa i dr. -2018
							</span>
							<input type="text" name="product_promo_audiovideo_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Izrada audio i video materijala, oglasa i dr - 2019.
							</span>
							<input type="text" name="product_promo_audiovideo_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Izrada audio i video materijala, oglasa i dr - 2020.(plan)
							</span>
							<input type="text" name="product_promo_audiovideo_2020" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Tiskanje promo./prodajnih materijala - 2018
							</span>
							<input type="text" name="product_promo_print_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Tiskanje promo./prodajnih materijala - 2019.
							</span>
							<input type="text" name="product_promo_print_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Tiskanje promo./prodajnih materijala - 2020.(plan)
							</span>
							<input type="text" name="product_promo_print_2020" value="" class="form-item__input js-form-input">
						</label>
						<p>
							Razrada oglašavanja (u EUR)
						</p>
						<label class="form-item">
							<span class="form-item__label ">
								Tisak - 2018.
							</span>
							<input type="text" name="product_advertismentcost_print_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Tisak - 2019.
							</span>
							<input type="text" name="product_advertismentcost_print_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Tisak - 2020.(plan)
							</span>
							<input type="text" name="product_advertismentcost_print_2020" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								TV - 2018.
							</span>
							<input type="text" name="product_advertismentcost_tv_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								TV - 2019.
							</span>
							<input type="text" name="product_advertismentcost_tv_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								TV - 2020.(plan)
							</span>
							<input type="text" name="product_advertismentcost_tv_2020" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Radio - 2018.
							</span>
							<input type="text" name="product_advertismentcost_radio_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Radio - 2019.
							</span>
							<input type="text" name="product_advertismentcost_radio_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Radio - 2020.(plan)
							</span>
							<input type="text" name="product_advertismentcost_radio_2020" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Web oglašavanje - 2018.
							</span>
							<input type="text" name="product_advertismentcost_web_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Web oglašavanje - 2019.
							</span>
							<input type="text" name="product_advertismentcost_web_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Web oglašavanje - 2020.(plan)
							</span>
							<input type="text" name="product_advertismentcost_web_2020" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Društvene mreže - 2018.
							</span>
							<input type="text" name="product_advertismentcost_social_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Društvene mreže - 2019.
							</span>
							<input type="text" name="product_advertismentcost_social_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Društvene mreže - 2020.(plan)
							</span>
							<input type="text" name="product_advertismentcost_social_2020" value="" class="form-item__input js-form-input">
						</label>
						<p>Razrada distribucije (u EUR)</p>
						<label class="form-item">
							<span class="form-item__label ">
								Organiziranje studijskih putovanja - 2018.
							</span>
							<input type="text" name="product_distributioncost_studytravel_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Organiziranje studijskih putovanja - 2019.
							</span>
							<input type="text" name="product_distributioncost_studytravel_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Organiziranje studijskih putovanja - 2020.(plan)
							</span>
							<input type="text" name="product_distributioncost_studytravel_2020" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Sudjelovanje na tematskim B2B poslovnim radionicama - 2018.
							</span>
							<input type="text" name="product_distributioncost_B2B_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Sudjelovanje na tematskim B2B poslovnim radionicama - 2019.
							</span>
							<input type="text" name="product_distributioncost_B2B_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Sudjelovanje na tematskim B2B poslovnim radionicama - 2020.(plan)
							</span>
							<input type="text" name="product_distributioncost_B2B_2020" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Nastupi na turističkim sajmovima - 2018.
							</span>
							<input type="text" name="product_distributioncost_touristfairs_2018" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Nastupi na turističkim sajmovima - 2019.
							</span>
							<input type="text" name="product_distributioncost_touristfairs_2019" value="" class="form-item__input js-form-input">
						</label>
						<label class="form-item">
							<span class="form-item__label ">
								Nastupi na turističkim sajmovima - 2020.(plan)
							</span>
							<input type="text" name="product_distributioncost_touristfairs_2020" value="" class="form-item__input js-form-input">
						</label>
					</div>
					<div v-show="currentStep == 6" class="form-div--6">
						<p>
							Podaci o postojećim programima složenih turističkih proizvoda za tržište posebnih interesa koje DMK već ima u svojoj ponudi
						</p>
						<label class="form-item">
							<span class="form-item__label ">
								Naziv najmanje tri (3) složena turistička proizvoda za tržište posebnih interesa koje korisnik ima u svojoj ponudi te web stranicu na kojoj su objavljeni. Obavezno navesti one programe koji se kandidiraju ako su objavljeni.
								Ukoliko na web stranicama nisu objavljeni cjeloviti podaci o programima putovanja za svaki navedeni proizvod treba priložiti odgovarajući promidžbeni materijal.
							</span>
							<textarea name="product_touristmarket" class="form-item__textarea js-form-input"></textarea>
						</label>
					</div> -->
				</form>
				<div v-if="formSuccess" class="form-status form-status--success">
					Formular je uspješno poslan.
				</div>
				<div class="main-form__btn-wrap">
					<button type="button" name="button" class="main-form__btn-prev" v-if="currentStep > 1" @click.prevent="previousStep">Prethodno</button>
					<button type="button" name="button" class="main-form__btn-next" v-if="currentStep < 4" @click.prevent="nextStep">Sljedeće</button>
					<button type="button" name="button" class="main-form__submit" v-else @click.prevent="submit">Pošalji</button>
				</div>
			</div>
			<div class="col-12 col-lg-2 u-order-sm-1">
				<dl class="form-steps__list">
					<dd class="form-steps__list-item" v-for="n in 4" :class="{active: currentStep == n }" :key="n">{{ n }}</dd>
				</dl>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Project Signup Form',
	data: () => ({
		currentStep:1,
		validationError: false,
		formSuccess: false,
		errorMsg: false,
		eventCooperation: [],
	}),
	methods: {
		previousStep() {
			this.currentStep--;
		},
		nextStep() {
			this.validate();

			if (!this.validationError && this.currentStep < 4) {
				this.currentStep++;
			}
			//scroll to top of the form
			$('html, body').animate({
				scrollTop: ($('#project-form').offset().top - 100)
			},0);
		},
		validate() {
			if(this.currentStep > 1) {
				this.validationError = false;
				let oldErrorInputs = $('.js-form-input').removeClass('has-error');

				let inputs = document.querySelectorAll('.form-div--' + this.currentStep + ' .js-form-input');
				let self = this;

				inputs.forEach(function(item) {
					if (item.value == "") {
						item.classList.add('has-error');

						if(!self.validationError) {
							self.validationError = true;
						}
					}
				});
			}
		},
		submit() {
			this.validate();

			//create FormData
			let formObject = document.querySelector('#project-form');
			let formData = new FormData(formObject);
			let self = this;

			axios.post('/en/xproject/signup',
				formData,
			).then(function (response) {
				// console.log(response);
				let responseData = response.data;

				if (responseData.status === true) {
					self.formSuccess = true;
					//reset form
					self.errorMsg = false;
					$('input').val('');
					$('textarea').val('');
					$('input[type=checkbox]').prop('checked', false);

				} else {
					self.errorMsg = true;
					Object.keys(responseData.form_error).forEach(item => {
						if (responseData.form_error[item]) {
							$('input[name='+ item + ']').addClass('has-error');
						}
					});
				}
			}).catch(function (error) {
				console.log(error);
			});
		}
	},
}
</script>

<style lang="scss">
.active {
	background-color: #1d1d1b;
			color: #c3c3c3;
}
.main-form__btn-wrap {
	display: flex;
	justify-content: space-between;
}

</style>
