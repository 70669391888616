<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <label class="c-search-form__title" for="search">{{msg.search_location}}</label>
                <input class="c-search-form__input" list="allboards" :placeholder="msg.search_location" type="text" @keydown.once="firstView = false" v-model="searchFilter" name="search">
                <datalist id="allboards">
                    <option v-for="board in boards" :key="board.name" :value="board.name"></option>
                </datalist>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div v-for="board in searchResults" :key="board" class="c-tourist-board__card">
                    <p class="c-tourist-board__card-title">{{board.name}}</p>
                    <dl class="c-tourist-board__list">
                        <div class="c-tourist-board__list-item-wrap">
                            <dt class="c-tourist-board__list-item-title">{{msg.block_label_adress}} </dt>

                            <dd class="c-tourist-board__list-item-description" v-if="board.google_maps_url!=''">
                                <a :href="board.google_maps_url">{{board.adress}}</a>
                            </dd>
                            <dd class="c-tourist-board__list-item-description" v-else>
                                {{board.adress}}
                            </dd>

                        </div>
                        <div class="c-tourist-board__list-item-wrap">
                            <dt class="c-tourist-board__list-item-title">{{msg.block_label_phone}} </dt><dd class="c-tourist-board__list-item-description"><a :href="'telto:' + board.phone">{{board.phone}}</a></dd>
                        </div>
                        <!-- <div class="c-tourist-board__list-item-wrap">
                            <dt class="c-tourist-board__list-item-title">{{msg.block_label_fax}} </dt><dd class="c-tourist-board__list-item-description">{{board.fax}}</dd>
                        </div> -->
                        <div class="c-tourist-board__list-item-wrap">
                            <dt class="c-tourist-board__list-item-title">{{msg.block_label_email}} </dt><dd class="c-tourist-board__list-item-description"><a :href="'mailto:' + board.email">{{board.email}}</a> </dd>
                        </div>
                        <div class="c-tourist-board__list-item-wrap">
                            <dt class="c-tourist-board__list-item-title">{{msg.block_label_website}} </dt><dd class="c-tourist-board__list-item-description"><a :href="board.website | urlChecker" target="_blank">{{board.website}}</a></dd>
                        </div>
                    </dl>
                </div>
                <div class="col-md-12 text-center">
                    <a v-if="firstView" href="#" @click.prevent="firstView = false" class="btn btn--load">
                        {{msg.btn_load_more}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

    export default {
        filters: {
            slugify: function (str) {
                str = str.replace(/^\s+|\s+$/g, ''); // trim
                str = str.toLowerCase();

                // remove accents, swap ñ for n, etc
                var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
                var to   = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";
                for (var i=0, l=from.length ; i<l ; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes

                return str;
            }
        },
        created() {
        },
        mounted() {
            axios.get('/hr/xtouristboard')
            .then(response => {
                if( typeof response.data.data.boards[0] !== "undefined" )
                {
                    response.data.data.boards[0].forEach(res_data => {
                        this.boards.push(res_data.meta);
                    });
                }
                this.boardNameLocalization();
            });

        },
        data: () => ({
            boards: [],
            searchFilter: "",
            firstView: true
            }),

        computed: {
            searchResults () {
                let filter = this.searchFilter.toLowerCase();
                if (this.firstView) {
                    return this.boards.slice(0,2);
                }
                else {
                    return this.boards.filter(function(item) {
                        let board = item.name.toLowerCase();
                        return board.match(filter);
                    });
                }
            },
            ...mapState({
                msg: state => state.msg
            })
        },
        methods: {
            loadMore() {
                this.firstView = !this.firstView;
            },
            gmapUrl (address, city) {

                let mapUrl = "https://www.google.com/maps/dir/?api=1&destination="
                address = address.replace(/^\s+|\s+$/g, ''); // trim
                city = city.replace(/^\s+|\s+$/g, ''); // trim
                address = address.toLowerCase();
                city = city.toLowerCase();

                // remove accents, swap ñ for n, etc
                var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
                var to   = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";
                for (var i=0, l=from.length ; i<l ; i++) {
                address = address.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                city = city.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                address = address.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes

                city = city.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes

                city = "+" + city;

                return mapUrl + address + city;
            },
            boardNameLocalization() {
                //msg-key localization for the first tourist board only(always "Zadarske zupanije") by replacing the name with msg key
                this.boards[0].name = this.msg.tourist_board_zadarske_zupanije;
            }
        }
    }
</script>
